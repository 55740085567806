.scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    z-index: 20;

    .scroll-visual {
        position: relative;
        height: 80px;
        width: 80px;
        padding-top: 20px;
        box-sizing: border-box;
        color: $home-text;
    }

    span {
        font-family: $condensed;
        font-size: 14px;
        position: absolute;
        top: 0;
        width: 100%;
        display: block;
        text-align: center;
    }

    .line {
        box-sizing: border-box;
        height: 60px;
        width: 2px;
        background-color: $home-text;
        position: absolute;
        bottom: 0;
        left: 50%;
        overflow: hidden;
    }

    .dot {
        width: 6px;
        height: 6px;
        background-color: $home-bg;
        position: relative;
        margin-left: -2px;
        z-index: 2;
    }
}

.home {
    .scroll-indicator {
        span {
            color: $home-accent;
        }
        .line {
            background-color: $home-accent;
        }
        .dot {
            background-color: $home-bg;
        }
    }
}

.about {
    .scroll-indicator {
        span {
            color: $about-accent;
        }
        .line {
            background-color: $about-accent;
        }
        .dot {
            background-color: $about-bg;
        }
    }
}

.services {
    .scroll-indicator {
        span {
            color: $services-accent;
        }
        .line {
            background-color: $services-accent;
        }
        .dot {
            background-color: $services-bg;
        }
    }
}

.team {
    .scroll-indicator {
        span {
            color: $team-accent;
        }
        .line {
            background-color: $team-accent;
        }
        .dot {
            background-color: $team-bg;
        }
    }
}

.project-detail-wrapper {
    .scroll-indicator {
        span {
            color: $black;
        }
        .line {
            background-color: $black;
        }
        .dot {
            background-color: $white;
        }
    }

    &.project-serenity-wrapper {
        .scroll-indicator {
            span {
                color: $serenity-text;
            }
            .line {
                background-color: $serenity-text;
            }
            .dot {
                background-color: $serenity-blue;
            }
        }
    }

    &.project-clc-wrapper {
        .scroll-indicator {
            span {
                color: $clc-text;
            }
            .line {
                background-color: $clc-text;
            }
            .dot {
                background-color: $clc-black;
            }
        }
    }

    &.project-startup-revival-wrapper {
        .scroll-indicator {
            span {
                color: $sr-text;
            }
            .line {
                background-color: $sr-text;
            }
            .dot {
                background-color: $sr-blue;
            }
        }
    }

    &.project-godtv-wrapper {
        .scroll-indicator {
            span {
                color: $gtv-text;
            }
            .line {
                background-color: $gtv-text;
            }
            .dot {
                background-color: $gtv-tan;
            }
        }
	}

    &.project-swatchit-wrapper {
        .scroll-indicator {
            span {
                color: $swatchit-heading;
            }
            .line {
                background-color: $swatchit-heading;
            }
            .dot {
                background-color: $swatchit-bg;
            }
        }
	}

    &.project-twotp-wrapper {
        .scroll-indicator {
            span {
                color: $twotp-heading;
            }
            .line {
                background-color: $twotp-heading;
            }
            .dot {
                background-color: $twotp-bg;
            }
        }
    }

    &.project-abf-wrapper {
        .scroll-indicator {
            span {
                color: $abf-text;
            }
            .line {
                background-color: $abf-text;
            }
            .dot {
                background-color: $abf-bg;
            }
        }
    }

    &.project-fbs-wrapper {
        .scroll-indicator {
            span {
                color: $fbs-heading;
            }
            .line {
                background-color: $fbs-heading;
            }
            .dot {
                background-color: $fbs-bg;
            }
        }
    }
}