// About
.about {
    background-color: $about-bg;

    .about-content {
        min-height: 60vh;
        padding: 3rem 0 0;

        .heading-big {
            font-size: 3.5rem;
            color: $about-accent;
            margin-bottom: 4rem;

            @media screen and (max-width: 500px) {
                font-size: 2.5rem;
            }
        }


        p {
            font-size: 1em;
            line-height: 1.8;
            color: $about-accent;

            @media screen and (max-width: 800px) {
                font-size: 1.2em;
            }
        }

        @media screen and (max-width: 800px) {
            .work-button-wrapper {
                text-align: center;
                padding: 24px 0;
            }
        }
    }

    .flex-columns {
        display: flex;
        flex-wrap: wrap;
        
        .about-left,
        .about-right {
            box-sizing: border-box;

            * {
                box-sizing: border-box;
            }
        }

        .about-left {
            width: 45%;
            padding-right: 3rem;
        }

        .about-right {
            width: 55%;
            position: relative;

            .logo-list-wrapper {
                position: relative;
                padding: 0 0 2.5rem 2.5rem;

                .line1 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1px;
                    height: 100%;
                    background-color: $about-accent;
                }
                .line2 {
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 50%;
                    height: 6px;
                    border-top: 1px solid $about-accent;
                    border-bottom: 1px solid $about-accent;
                }
            }
        }

        .logo-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            li {
                list-style: none;
                width: 33.3333%;
                padding: 1rem;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    max-width: 6rem;
                    max-height: 2.5rem;
                }
            }
        }

        @media screen and (max-width: 1150px) {
            .about-right,
            .about-left {
                width: 50%;
            }

            .about-right {
                .logo-list-wrapper {
                    padding: 0 0 1.5rem 1.5rem;
                }
            }

            .logo-list {
                li {
                    width: 50%;
                }
            }
        }

        @media screen and (max-width: 960px) {
            .about-right,
            .about-left {
                width: 100%;
            }

            .about-left,
            .about-right {
                margin-bottom: 5rem;
            }

            .logo-list {
                li {
                    width: 33.3333%;
                }
            }
        }


        @media screen and (max-width: 460px) {
            .logo-list {
                li {
                    width: 50%;
                }
            }
        }
    }
}