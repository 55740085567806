.main-footer {
    position: fixed;
    left: 94vw;
    top: 25vh;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-family: $condensed;
    letter-spacing: 4px;
    line-height: 1rem;
    transform: rotate(90deg);
    transform-origin: top left;
    width: 100vh;
    display: block;
    line-height: 1rem;
    z-index: 10;
    overflow: hidden;

    @media screen and (max-height: 700px) {
        letter-spacing: 3px;
    }

    @media screen and (max-width: 620px) {
        left: 97.5vw;
        letter-spacing: 2px;
    }

    span {
        position: relative;
        display: inline-block;
        padding: 0 0.75rem;
        margin: 0;
        vertical-align: top;

        &::after {
            content: '';
            position: absolute;
            background-color: $black;
            width: 1px;
            height: 0.7rem;
            top: 2px;
            right: 0;
        }
        
        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    .icon-texas {
        width: 1rem;
        height: 1rem;
        display: inline-block;
        line-height: 1rem;
        vertical-align: middle;
        margin: 0 0.25rem;
    }
}

.work {
    .main-footer {
        transform: rotate(0deg);
        top: auto;
        left: 0;
        text-align: center;
        width: 100vw;
        bottom: 10vh;
        color: $black;

        @media screen and (max-width: 500px) {
            letter-spacing: 1px;
            bottom: 1.5rem;
        }

        .icon-texas {
            fill: $black;
        }
    }
}

.home {
    .main-footer {
        color: $home-accent;

        span::after {
            background-color: $home-accent;
        }
        
        .icon-texas {
            fill: $home-accent;
        }
    }
}

.about {
    .main-footer {
        color: $about-accent;

        span::after {
            background-color: $about-accent;
        }
        
        .icon-texas {
            fill: $about-accent;
        }
    }
}

.services {
    .main-footer {
        color: $services-accent;

        span::after {
            background-color: $services-accent;
        }
        
        .icon-texas {
            fill: $services-accent;
        }
    }
}

.team {
    .main-footer {
        color: $team-accent;

        span::after {
            background-color: $team-accent;
        }
        
        .icon-texas {
            fill: $team-accent;
        }
    }
}

.contact {
    .main-footer {
        color: $contact-accent;

        span::after {
            background-color: $contact-accent;
        }
        
        .icon-texas {
            fill: $contact-accent;
        }
    }
}

.work {
    .main-footer {
        color: $work-accent;

        span::after {
            background-color: $work-accent;
        }
        
        .icon-texas {
            fill: $work-accent;
        }
    }
}

.project-detail-wrapper {
    .main-footer {
        color: $white;

        span::after {
            background-color: $white;
        }
        
        .icon-texas {
            fill: $white;
        }
    }
}