.wipe-right,
.wipe-left,
.wipe-up,
.wipe-down {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    opacity: 1;
}

.wipe-right {    
    top: 0;
    bottom: 0;
    left: -100vw;
    background-color: $white;
}

.wipe-left {    
    top: 0;
    right: -100vw;
    bottom: 0;
    background-color: $white;
}

.wipe-up {
    top: 100vh;
    right: 0;
    left: 0;
}

.wipe-down {
    bottom: 100vh;
    right: 0;
    left: 0;
}

.wipe-home {
    background-color: $home-bg;
}
.wipe-about {
    background-color: $about-bg;
}
.wipe-services {
    background-color: #f3e5db;
}
.wipe-team {
    background-color: #ffe7e5;
}
.wipe-work {
    background-color: $white;
}
.wipe-contact {
    background-color: $contact-bg
}