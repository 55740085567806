#container.contact {
    background-color: $contact-bg;
    color: $contact-accent;

    .state-logo {
        svg {
            fill: $contact-accent;
        }
    }

    .main-nav {
        display: none;
    }

    .hire-us-content {
        min-height: 60vh;
        padding: 80px 0 0;
        max-width: 54rem;
        margin: auto;

        h1.heading-big {
            font-size: 3rem;
            color: $contact-accent;
            margin-bottom: 4rem;
            max-width: none;

            @media screen and (max-width: 500px) {
                font-size: 2.5rem;
            }
        }

        p {
            font-size: 0.9em;
            line-height: 1.8;
            color: $contact-accent;
        }
    }

    .contact-details {
        display: flex;
        flex-wrap: wrap;

        .contact-left {
            width: 50%;
            margin-bottom: 8rem;
            position: relative;

            form {
                position: relative;
                max-width: 520px;

                label {
                    position: relative;
                    display: block;
                    font-family: $condensed;
                    font-size: 0.7em;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    width: 100%;
                    padding-bottom: 1.75rem;

                    &:nth-last-of-type(2) {
                        padding-bottom: 2.5rem;

                        span {
                            bottom: 1.5rem;
                        }
                    }
                    &:last-of-type {
                        padding-bottom: 0;
                    }

                    div {
                        display: inline;
                        position: absolute;
                        left: 0;
                        top: 0.75rem;

                        &.textarea-label-text {
                            left: 0.75rem;
                        }
                    }

                    span {
                        position: absolute;
                        right: 0.5rem;
                        bottom: 0.8rem;
                    }

                    input,
                    textarea {
                        box-sizing: border-box;
                        width: 100%;
                        outline: none;
                        border: none;
                        background-color: $contact-bg;
                        border-bottom: 2px solid $contact-accent;
                        border-radius: 0;
                        padding: 0.75rem 0.5rem 0.5rem;
                        font-family: $sans-serif;
                        font-size: 0.85rem;
                        color: $contact-accent;
                    }
                    input {
                        height: 2rem;
                        line-height: 2rem;
                    }
                    textarea {
                        height: 6rem;
                        resize: none;
                        border: 2px solid $contact-accent;
                    }
                }

                [type="submit"] {
                    position: absolute;
                    bottom: -2.5rem;
                    right: 0;
                    background-color: $contact-accent;
                    color: $contact-bg;
                    border: none;
                    outline: none;
                    height: 2.5rem;
                    line-height: 2.5rem;
                    padding: 0 2rem;
                    font-family: $condensed;
                    letter-spacing: 3px;
                    font-size: 0.7em;
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    
                    &:hover,
                    &:focus {
                        background-color: lighten($contact-accent, 5%);
                    }
                }
            }

            .form-message {
                padding-top: 2.5rem;
                
                span {
                    font-family: $serif;
                    font-size: 1.5rem;
                }
            }
        }
        .contact-right {
            width: 50%;
            box-sizing: border-box;
            padding-left: 20px;
        }

        
        @media screen and (max-width: 900px) {
            .contact-left,
            .contact-right {
                width: 100%;
            }
            .contact-right {
                padding-left: 0;
            }
        }

        .badge-logo {
            text-align: center;
            margin-bottom: 2rem;
    
            svg {
                width: 314px;
                height: 278px;
            }

            @media screen and (max-width: 640px) {
                svg {
                    width: 236px;
                    height: 210px;
                }
            }
        }

        .social-icons {
            list-style-type: none;
            display: flex;
            flex-wrap: nowrap;
            margin: 0 auto 2rem;
            padding: 0;
            align-items: center;
            justify-content: center;
            max-width: 300px;

            li {
                width: 25%;
                text-align: center;
                overflow: hidden;

                a {
                    text-align: center;
                    padding: 0.5rem;
                    display: inline-block;
                }

                svg {
                    width: 2rem;
                    height: 32px;
                    fill: $contact-accent;
                    transition: all 0.3s ease;

                    &.icon-instagram {
                        height: 36px;
                    }
                    &.icon-youtube {
                        height: 32px;
                    }
                }  
                
                &:hover,
                &:focus {
                    svg {
                        fill: lighten($contact-accent, 5%);
                    }
                }
            }
        }

        .contact-address {
            list-style-type: none;
            padding: 0;
            margin: 0 auto;
            font-family: $condensed;
            color: $contact-accent;
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 1px;
            max-width: 260px;

            li {
                margin-bottom: 0.5rem;
                overflow: hidden;
            }

            span {
                display: inline-block;
            }

            a {
                display: inline-block;
                color: $contact-accent;
                text-decoration: none;
                transition: all 0.3s ease;

                &:hover,
                &:focus {
                    color: lighten($contact-accent, 5%);
                }
            }

            @media screen and (max-width: 640px) {
                font-size: 1rem;

                li {
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
}