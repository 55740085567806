.team-list {
    list-style-type: none;
    max-width: 54rem;
    margin: 0 auto 5rem;
    padding: 0;
    color: $team-text;

    li {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 7rem;

        @media screen and (max-width: 768px) {
            margin-bottom: 3.5rem;
        }
        
        .team-portrait {
            width: 35%;

            img {
                width: 100%;
            }
        }

        .team-detail {
            width: 65%;
            box-sizing: border-box;
            padding: 0 2.5rem;

            @media screen and (max-width: 850px) {
                padding: 0 1rem;
            }

            p {
                font-size: 1em;
                line-height: 1.8;
                margin-bottom: 0;

                @media screen and (max-width: 768px) {
                    font-size: 1.1em;
                }
            }
        }

        h3 {
            font-family: $serif;
            display: inline-block;
            font-size: 2rem;
            color: $team-text;
            padding-bottom: 0.75rem;
            margin-top: 0;
            margin-bottom: 0.75rem;
            overflow: hidden;
            position: relative;
    
            span {
                display: inline-block;
            }
    
            .line {
                box-sizing: border-box;
                height: 4px;
                width: 100%;
                border-top: 1px solid $team-accent;
                border-bottom: 1px solid $team-accent;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        span.team-subtitle {
            display: block;
            font-family: $condensed;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 1em;
            margin-bottom: 1rem;
            overflow: hidden;

            @media screen and (max-width: 768px) {
                font-size: 1.1em;
            }
    
            div {
                display: inline-block;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
        }

        @media screen and (max-width: 750px) {
            flex-wrap: wrap;

            .team-portrait {
                width: 100%;
                text-align: center;

                img {
                    max-width: 250px;
                    margin-bottom: 1rem;
                }
            }

            .team-detail {
                width: 100%;
                padding: 0 0.5rem;
                text-align: center;

                p {
                    max-width: 400px;
                    margin: 0 auto 2rem;
                }
            }
        }

        @media screen and (max-width: 500px) {
            .team-portrait {
                img {
                    max-width: 180px;
                }
            }
        }
    }

   
}