// Services
.services {
    background-color: $services-bg;
    color: $services-text;


    .services-detail {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4rem;

        .services-text,
        .services-list {
            box-sizing: border-box;
        }

        .services-text {
            width: 50%;
            padding: 0 3rem 0 0;
        }

        .services-list {
            position: relative;
            width: 50%;
            font-family: $condensed;

            ul {
                margin: 0;
                padding: 1.5rem 0.5rem 0 3rem;
                list-style-type: none;
                column-count: 2;
                column-gap: 16px;

                &.icon-list {
                    padding: 1.5rem 0 2.5rem 1rem;
                    font-size: 18px;

                    li {
                        line-height: 1.5rem;
                        position: relative;

                        svg {
                            width: 36px;
                            height: 28px;
                            vertical-align: middle;
                            fill: $services-accent;

                            path, rect, circle {
                                fill: $services-accent;
                            }
                        }
                        span {
                            margin-left: 2rem;

                            @media screen and (max-width: 1300px) {
                                margin-left: 1rem;
                            }
                        }
                    }

                    @media screen and (max-width: 1144px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 1050px) {
                        font-size: 14px;

                        li {
                            span {
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }

            li {
                margin: 0 0 0.75rem;
                text-transform: uppercase;
                letter-spacing: 1px;
                overflow: hidden;

                span {
                    display: inline-block;
                }
            }

            .small-services-list {
                position: relative;
                box-sizing: border-box;

                .line1,
                .line2,
                .line-solid {
                    box-sizing: border-box;
                    display: inline-block;
                    position: absolute;
                }

                .line1 {
                    top: 0;
                    left: 2rem;
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    background-color: $services-accent;
                }

                .line2 {
                    bottom: 1rem;
                    left: 0.8rem;
                    height: 5px;
                    border-top: 1px solid $services-accent;
                    border-bottom: 1px solid $services-accent;
                    width: 50%;
                    position: absolute;
                }

                .line-solid {
                    bottom: 1rem;
                    left: 54%;
                    width: 2.5rem;
                    height: 5px;
                    background-color: $services-accent;
                }

                ul {
                    font-size: 0.75em;
                    padding: 0.5rem 0 2rem 2rem;
                    margin-left: 2rem;
                }
            }
        }

        @media screen and (max-width: 1023px) {
            .services-text {
                width: 100%;
                padding: 0;
                margin-bottom: 1rem;

                p {
                    max-width: 600px;
                }

                .work-button-wrapper {
                    padding: 2rem;
                }
            }

            .services-list {
                width: 100%;
                display: flex;

                .icon-list {
                    width: 50%;
                }
                .small-services-list {
                    width: 50%;
                }
            }
        }

        @media screen and (max-width: 920px) {
            .services-list {
                ul {
                    column-count: 1;
                    column-gap: 0;
                }

                .icon-list {
                    font-size: 1em;
                    padding-top: 0;
                    padding-left: 0;
                    
                    li {
                        margin-bottom: 1rem;
                    }
                }

                .small-services-list {
                    ul {
                        font-size: 1em;
                        margin-left: 0;
                        
                        li {
                            margin-bottom: 1rem;
                        }
                    }

                    .line1 {
                        left: 1rem;
                    }
                    .line2 {
                        left: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            .services-text {
                .work-button-wrapper {
                    text-align: center;
                    margin-bottom: 2rem;
                }
            }
        }

        @media screen and (max-width: 480px) {
            .services-list {
                margin: auto;
                margin: 0 auto;

                ul.icon-list {
                    font-size: 12px;
                    padding: 0;
                    
                    li {
                        margin-bottom: 16px;

                        svg {
                            width: 26px;
                            height: 18px;
                        }

                        span {
                            margin-right: 0;
                        }
                    }
                }

                .small-services-list {

                    ul {
                        font-size: 12px;
                        padding: 0 0 40px 16px;
                    
                        li {
                            margin: 0 0 12px;    
                        }
                    }

                    .line1 {
                        left: 4px;
                    }

                    .line2 {
                        left: -9px;
                        width: calc(100% - 4rem);
                    }

                    .line-solid {
                        left: 56%;
                    }
                }
            }
        }
    }

    .services-content {
        padding-top: 3rem;
    }

    h1.heading-big {
        color: $services-text;
        font-size: 3rem;
        max-width: 39rem;
        margin-bottom: 2.75rem;

        @media screen and (max-width: 500px) {
            font-size: 2.5rem;
        }
    }
}