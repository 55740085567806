// Home
.home {
    .home-content {
        padding: 3rem 0 0;

        h1 {
            margin-bottom: 3rem;

            @media screen and (max-width: 500px) {
                font-size: 4rem;
            }

            @media screen and (max-width: 374px) {
                font-size: 3.5rem;
            }
        }

        .double-lines {
            box-sizing: border-box;
            width: 100%;
            max-width: 660px;
            height: 6px;
            border-top: 1px solid $orange;
            border-bottom: 1px solid $orange;
            margin-bottom: 1rem;
        }

        ul {
            margin: 0 0 3rem;
            padding: 0;
            width: 100%;
            max-width: 660px;
            list-style-type: none;
            overflow: hidden;

            li {
                font-family: $condensed;
                font-weight: normal;
                display: inline-block;
                text-transform: uppercase;
                color: $purple;
                letter-spacing: 3px;
                margin-right: 0.4rem;
            }
        }
        
    }
}