// Work
#container.work {
    background-color: $white;
    overflow: hidden;
    padding-top: 0;

    .wrap {
        padding: 0;
    }

    #scrollRouterTrigger {
        height: 100vh;
    }
}