// Team
.team {
    background-color: $team-bg;

    .logo-svg {
        fill: $team-accent;
    }

    .team-content {
        padding-top: 3rem;
        max-width: 54rem;
        margin: auto;
    }

    h1.heading-big {
        color: $team-text;
        font-size: 3rem;
        max-width: 43rem;
        margin-bottom: 7rem;
        
        @media screen and (max-width: 768px) {
            margin-bottom: 3.5rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 2.8rem;
        }
    }
}