// Scaling text
html {
font-size: calc(14px + 8 * ((100vw - 500px) / 1500));
}

body {
    font-family: $sans-serif;
    font-size: 1rem;
}

h1.heading-big {
    color: $purple;
    font-family: $serif;
    font-size: 4.5rem;
    font-weight: 900;
    line-height: 0.9;
    margin: 0;
    margin-left: -1rem;
    max-width: 40rem;

    @media screen and (max-width: 768px) {
        line-height: 0.8;
    }

    span {
        display: inline-block;
        transform-origin: bottom;
        overflow: hidden;

        div {
            padding: 0 0 14px 1rem;
        }
    }

    @media screen and (max-width: 946px) {
        max-width: 720px;
    }
    @media screen and (max-width: 500px) {
        font-size: 2.5rem;
    }
}

p {
    line-height: 1.5;
    margin: 0 0 2rem;

    @media screen and (max-width: 500px) {
        font-size: 16px;
    }
}