.background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.work-list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    padding: 0;
    z-index: 1;

    &.disable-vertical-scroll {
        overflow: hidden;
    }
    
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}

.work-spacer {
    position: absolute;
}

.work-item {
    display: block;
    position: absolute;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    
    &.absolute,
    .absolute {
        position: absolute;
    }

    &.fixed,
    .fixed {
        position: fixed;
    }


    @media screen and (max-width: 769px) {
        &.project-startup-revival {
            .background-image {
                background-position: center right;
            }
        }
    }

    > a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    .project-list-header {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        top: 2rem;
        right: 2rem;
        bottom: 2rem;
        left: 2rem;
        z-index: 1;
        padding: 3rem 1.5rem;
        text-align: center;
        transform-origin: center;
        color: $white;

        h2 {
            font-family: $serif;
            font-size: 2.75rem;
            line-height: 0.9;
            font-weight: 900;
            margin: 0 auto 0.75rem;
            position: relative;
        }

        p {
            font-family: $condensed;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin: 0;
            font-size: 0.7rem;
        }
    }
}

// .work-item:hover {
//     .background-image {
//         transition: all 0.5s ease-in-out;
//         transform: scale(1);
//     }
// }