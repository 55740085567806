.main-header {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    padding: 2rem 0 0;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    justify-content: center;
    max-width: none;
    position: fixed;
    width: 100%;
    top: 0;

    .button-back-wrapper,
    .button-hire-wrapper {
        display: none;
        visibility: hidden;
    }

    .state-logo {
        width: 50%;
        position: relative;
        text-align: center;
        z-index: 1;

        @media screen and (max-width: 620px) {
            width: 33.333333%;
        }
    
        .logo-wrapper {
            display: inline-block;
            cursor: pointer;
        }
    
        svg {
            width: 100%;
            max-width: 150px;
            max-height: 38px;
            transition: fill 0.3s ease-in-out;
        }
    }

    .button-back-wrapper,
    .button-next-wrapper,
    .button-hire-wrapper {
        display: block;
        visibility: visible;
        width: 25%;
        position: relative;

        button {
            cursor: pointer;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            border: none;
            outline: none;
            font-family: $condensed;
            font-size: 0.8rem;
            line-height: 32px;
            height: 32px;
            letter-spacing: 2px;
            text-transform: uppercase;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            .line {
                display: block;
                width: 3rem;
                height: 2px;
                background-color: $black;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: width 0.5s ease;
            }

            &:hover {
                .line {
                    width: 2rem;
                    
                    @media screen and (max-width: 620px) {
                        width: 1.5rem;
                    }
                }
            }
        }

        @media screen and (max-width: 620px) {
            width: 33.333333%;

            button {
                .line {
                    width: 1.5rem;
                }
            }
        }
    }

    .button-back-wrapper {
        text-align: left;

        button {
            padding-left: 3.5rem;

            .line {
                left: 0;
            }

            @media screen and (max-width: 620px) {
                padding-left: 2rem;
            }
        }
    }
    .button-next-wrapper,
    .button-hire-wrapper {
        text-align: right;

        button {
            padding-right: 3.5rem;

            .line {
                right: 0;
            }

            @media screen and (max-width: 620px) {
                padding-right: 2rem;
            }
        }
    }
   
    &.alt-header {
        justify-content: center;
        max-width: none;

        .button-back-wrapper,
        .button-next-wrapper {
            display: block;
            visibility: visible;
            width: 25%;
            position: relative;

            @media screen and (max-width: 620px) {
                width: 33.333333%;
    
                button {
                    .line {
                        width: 1.5rem;
                    }
                }
            }
            
            button {
                cursor: pointer;
                top: 50%;
                transform: translateY(-50%);
                background-color: transparent;
                border: none;
                outline: none;
                font-family: $condensed;
                font-size: 0.8rem;
                line-height: 32px;
                height: 32px;
                letter-spacing: 2px;
                text-transform: uppercase;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                .line {
                    display: block;
                    width: 3rem;
                    height: 2px;
                    background-color: $black;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover {
                    .line {
                        width: 2rem;
                        
                        @media screen and (max-width: 620px) {
                            width: 1.5rem;
                        }
                    }
                }
            }
        }

        .button-back-wrapper {
            text-align: left;

            button {
                text-align: left;
                left: 0;
                padding-left: 3.5rem;

                .line {
                    left: 0;
                }

                @media screen and (max-width: 620px) {
                    padding-left: 2rem;
                    letter-spacing: 1.5px;

                    .line {
                        width: 1.5rem;
                    }
                }
            }
        }
        .button-next-wrapper,
        .button-hire-wrapper {
            text-align: right;
    
            button {
                text-align: right;
                right: 0;
                padding-right: 3.5rem;

                @media screen and (max-width: 620px) {
                    padding-right: 2rem;
                }
    
                .line {
                    right: 0;
                }
            }
        }
        .state-logo {
            text-align: center;
        }
        
        .main-nav {
            display: none;
        }
    }
}

.work {
    .logo-svg {
        fill: $work-accent;
    }

    .main-header  {
       .main-nav {
           visibility: hidden;
       }

        .button-back-wrapper,
        .button-hire-wrapper {

            button {
                color: $work-accent;

                .line {
                    background-color: $work-accent;
                }
            }
        }
    }
}

.home {
    .main-header {
        justify-content: unset;
        margin-right: auto;
        margin-left: auto;
        max-width: none;
        margin: 0 auto;
        padding: 2rem 0 20px 15vw;

        @media screen and (max-width: 1024px) {
            padding: 2rem 0 20px 20px;
        }
    }

    .button-back-wrapper {
        display: none;
        visibility: hidden;
    }

    .state-logo {
        width: 33%;
    }

    .button-hire-wrapper {
        width: 67%;

        button {
            color: $home-accent;
            
            .line {
                background-color: $home-accent;
            }
        }
    }

    .state-logo {
        text-align: left;
    }
    .logo-svg {
        fill: $home-accent;
    }
}

.about {
    .logo-svg {
        fill: $about-accent;
    }

    .main-header  {
        .button-back-wrapper,
        .button-hire-wrapper {

            button {
                color: $about-accent;

                .line {
                    background-color: $about-accent;
                }
            }
        }
    }
}

.services {
    .logo-svg {
        fill: $services-accent;
    }

    .main-header  {
        .button-back-wrapper,
        .button-hire-wrapper {

            button {
                color: $services-accent;

                .line {
                    background-color: $services-accent;
                }
            }
        }
    }
}

.team {
    .logo-svg {
        fill: $team-accent;
    }

    .main-header  {
        .button-back-wrapper,
        .button-hire-wrapper {

            button {
                color: $team-accent;

                .line {
                    background-color: $team-accent;
                }
            }
        }
    }
}

.contact {
    .logo-svg {
        fill: $contact-accent;
    }

    .main-header .button-back-wrapper,
    .main-header .button-next-wrapper {
        button {
            color: $contact-accent;

            .line {
                background-color: $contact-accent;
            }
        }
    }
}


.project-detail-wrapper {
    .logo-svg {
        fill: $white;
    }

    .main-header  {
        .button-back-wrapper,
        .button-hire-wrapper {
            button {
                color: $white;

                .line {
                    background-color: $white;
                }
            }
        }
    }
}