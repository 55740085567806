.work-button-wrapper {
    padding-top: 0.5rem;
}

.work-button {
    display: inline-block;

    button {
        display: block;
        position: relative;
        min-width: 160px;
        border-top: 2px solid $about-accent;
        border-right: none;
        border-bottom: 2px solid $about-accent;
        border-left: none;
        background-color: $about-accent;
        padding: 0 36px;
        line-height: 40px; 
        font-family: $condensed;
        font-size: 18px;
        letter-spacing: 2px;
        color: $about-bg;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
        transition: all 350ms ease-in-out;
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            border-top: 2px solid $about-accent;
            border-bottom: 2px solid $about-accent;
            background-color: transparent;
            color: $about-accent;
        }
        &::selection {
            background: transparent;
            color: inherit;
        }
    
        @media screen and(max-width: 480px) {
            padding: 0 32px;
            line-height: 40px;
            font-size: 16px;
        }
    
        span {
            display: block;
            position: absolute;
            top: 50%;
            content: '';
            transition: all 350ms ease-in-out;
        }

        .right { right: 0; }
        .left { left: 0; }

        .vert {
            z-index: 90; 
            width: 2px; 
            height: 50%; 
            background-color: $about-accent;
        }

        .horz {
            z-index: 99; 
            width: 20px; 
            height: 2px; 
            background-color: $about-bg;
        }

        .top.vert { top: 0; bottom: unset; }
        .bot.vert { top: unset; bottom: 0; }

        .top.horz { transform: translateY(-1px); }
        .bot.horz { transform: translateY(1px); }

        &:hover {
            .vert { height: calc(50% - 5px); }

            .horz {
                width: 10px;
                background-color: $about-accent;
            }

            .top.horz { transform: translateY(-5px); }
            .bot.horz { transform: translateY(5px); }
        }
    }
}

.project-detail-wrapper {
    .work-button {
        button {
            line-height: 30px;
            font-size: 14px;
            padding: 0 28px;
        }
    }
}


.home {
    .work-button {
        button {
            border-top-color: $home-accent;
            border-bottom-color: $home-accent;
            background-color: $home-accent;
            color: $home-bg;
            
            &:hover,
            &:focus {
                border-top-color: $home-accent;
                border-bottom-color: $home-accent;
                background-color: transparent;
                color: $home-accent;
            }
        
            .vert {
                background-color: $home-accent;
            }
            .horz {
                background-color: $home-bg;
            }
            &:hover {
                .horz { background-color: $home-accent; }
            }
        }
    }
}
.about {
    .work-button {
        button {
            border-top-color: $about-accent;
            border-bottom-color: $about-accent;
            background-color: $about-accent;
            color: $about-bg;
            
            &:hover,
            &:focus {
                border-top-color: $about-accent;
                border-bottom-color: $about-accent;
                background-color: transparent;
                color: $about-accent;
            }
        
            .vert {
                background-color: $about-accent;
            }
            .horz {
                background-color: $about-bg;
            }
            &:hover {
                .horz { background-color: $about-accent; }
            }
        }
    }
}
.services {
    .work-button {
        button {
            border-top-color: $services-accent;
            border-bottom-color: $services-accent;
            background-color: $services-accent;
            color: $services-bg;
            
            &:hover,
            &:focus {
                border-top-color: $services-accent;
                border-bottom-color: $services-accent;
                background-color: transparent;
                color: $services-accent;
            }
        
            .vert {
                background-color: $services-accent;
            }
            .horz {
                background-color: $services-bg;
            }
            &:hover {
                .horz { background-color: $services-accent; }
            }
        }
    }
}
/********** PROJECT DETAIL ***********/

.project-clc-wrapper {
    .work-button {
        button {
            border-top-color: $clc-red;
            border-bottom-color: $clc-red;
            background-color: $clc-red;
            color: $clc-black;
            
            &:hover,
            &:focus {
                border-top-color: $clc-red;
                border-bottom-color: $clc-red;
                background-color: transparent;
                color: $clc-red;
            }
        
            .vert {
                background-color: $clc-red;
            }
            .horz {
                background-color: $clc-black;
            }
            &:hover {
                .horz { background-color: $clc-red; }
            }
        }
    }
}

.project-serenity-wrapper {
    .work-button {
        button {
            border-top-color: $serenity-orange;
            border-bottom-color: $serenity-orange;
            background-color: $serenity-orange;
            color: $serenity-blue;
            
            &:hover,
            &:focus {
                border-top-color: $serenity-orange;
                border-bottom-color: $serenity-orange;
                background-color: transparent;
                color: $serenity-orange;
            }
        
            .vert {
                background-color: $serenity-orange;
            }
            .horz {
                background-color: $serenity-blue;
            }
            &:hover {
                .horz { background-color: $serenity-orange; }
            }
        }
    }
}

.project-startup-revival-wrapper {
    .work-button {
        button {
            border-top-color: $sr-text;
            border-bottom-color: $sr-text;
            background-color: $sr-text;
            color: $sr-blue;
            
            &:hover,
            &:focus {
                border-top-color: $sr-text;
                border-bottom-color: $sr-text;
                background-color: transparent;
                color: $sr-text;
            }
        
            .vert {
                background-color: $sr-text;
            }
            .horz {
                background-color: $sr-blue;
            }
            &:hover {
                .horz { background-color: $sr-text; }
            }
        }
    }
}

.project-godtv-wrapper {
    .work-button {
        button {
            border-top-color: $gtv-text;
            border-bottom-color: $gtv-text;
            background-color: $gtv-text;
            color: $gtv-tan;
            
            &:hover,
            &:focus {
                border-top-color: $gtv-text;
                border-bottom-color: $gtv-text;
                background-color: transparent;
                color: $gtv-text;
            }
        
            .vert {
                background-color: $gtv-text;
            }
            .horz {
                background-color: $gtv-tan;
            }
            &:hover {
                .horz { background-color: $gtv-text; }
            }
        }
    }
}

.project-swatchit-wrapper {
    .work-button {
        button {
            border-top-color: $swatchit-heading;
            border-bottom-color: $swatchit-heading;
            background-color: $swatchit-heading;
            color: $swatchit-bg;
            
            &:hover,
            &:focus {
                border-top-color: $swatchit-heading;
                border-bottom-color: $swatchit-heading;
                background-color: transparent;
                color: $swatchit-heading;
            }
        
            .vert {
                background-color: $swatchit-heading;
            }
            .horz {
                background-color: $swatchit-bg;
            }
            &:hover {
                .horz { background-color: $swatchit-heading; }
            }
        }
    }
}

.project-twotp-wrapper {
    .work-button {
        button {
            border-top-color: $twotp-heading;
            border-bottom-color: $twotp-heading;
            background-color: $twotp-heading;
            color: $twotp-bg;
            
            &:hover,
            &:focus {
                border-top-color: $twotp-heading;
                border-bottom-color: $twotp-heading;
                background-color: transparent;
                color: $twotp-heading;
            }
        
            .vert {
                background-color: $twotp-heading;
            }
            .horz {
                background-color: $twotp-bg;
            }
            &:hover {
                .horz { background-color: $twotp-heading; }
            }
        }
    }
}

.project-abf-wrapper {
    .work-button {
        button {
            border-top-color: $abf-text;
            border-bottom-color: $abf-text;
            background-color: $abf-text;
            color: $abf-bg;
            
            &:hover,
            &:focus {
                border-top-color: $abf-text;
                border-bottom-color: $abf-text;
                background-color: transparent;
                color: $abf-text;
            }
        
            .vert {
                background-color: $abf-text;
            }
            .horz {
                background-color: $abf-bg;
            }
            &:hover {
                .horz { background-color: $abf-text; }
            }
        }
    }
}

.project-fbs-wrapper {
    .work-button {
        button {
            border-top-color: $fbs-heading;
            border-bottom-color: $fbs-heading;
            background-color: $fbs-heading;
            color: $fbs-bg;
            
            &:hover,
            &:focus {
                border-top-color: $fbs-heading;
                border-bottom-color: $fbs-heading;
                background-color: transparent;
                color: $fbs-heading;
            }
        
            .vert {
                background-color: $fbs-heading;
            }
            .horz {
                background-color: $fbs-bg;
            }
            &:hover {
                .horz { background-color: $fbs-heading; }
            }
        }
    }
}