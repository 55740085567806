.main-nav {
    position: fixed;
    left: 0;
    top: 9rem;

    @media screen and (max-width: 768px) {
        visibility: hidden;
    }

    ul {
        position: relative;
        overflow: hidden;
        list-style-type: none;
        margin: 0;
        z-index: 100;
        padding-left: 4.5vw;

        @media screen and (max-width: 700px) {
            padding-left: 3vw;
        }
        @media screen and (max-width: 620px) {
            padding-left: 0;
        }
        
        li {
            font-size: 2rem;
            font-family: $serif;
            margin: 0 auto;
            
            a {
                box-sizing: border-box;
                padding: 0 20px;
                margin-bottom: 3px;
                display: block;
                text-decoration: none;
                cursor: pointer;
                position: relative;

                @media screen and (max-width: 620px) {
                    padding: 0 4.5vw;
                }

                span.nav-text {
                    font-family: $condensed;
                    text-transform: uppercase;
                    font-size: 10px;
                    letter-spacing: 3px;
                    transform: rotate(-90deg)
                               translateX(calc(-50% + 6px));
                    display: block;
                    position: absolute;
                    left: -14px;
                    top: 0;
                    text-align: center;
                    width: 68px;
                    overflow: hidden;

                    div {
                        transform: translateY(32px);
                        transition: transform 0.5s ease;
                    }
                }
                
                span.bar {
                    display: block;
                    width: 3px;
                    height: 68px;
                    background-color: $purple;
                    transform: translateX(0);
                    transition: transform 0.5s ease;
                }

                &:hover {
                    span.nav-text {
                        div {
                            transform: translateY(0);
                        }
                    }
                    span.bar {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
}

.home {
    .main-nav ul li a {
        color: $home-accent;

        span.bar {
            background-color: $home-accent;
    
        }
        &.nav-home {
            span.bar {
                background-color: transparent;
                border-color: $home-accent;
                box-sizing: border-box;
                background-color: transparent;
                border-right: 1px solid $home-accent;
                border-left: 1px solid $home-accent;
            }
        }
    }
}

.about {
    .main-nav ul li a {
        color: $about-accent;

        span.bar {
            background-color: $about-accent;
    
        }
        &.nav-about {
            span.bar {
                background-color: transparent;
                border-color: $about-accent;
                box-sizing: border-box;
                background-color: transparent;
                border-right: 1px solid $about-accent;
                border-left: 1px solid $about-accent;
            }
        }
    }
}

.services {
    .main-nav ul li a {
        color: $services-accent;

        span.bar {
            background-color: $services-accent;
    
        }
        &.nav-services {
            span.bar {
                background-color: transparent;
                border-color: $services-accent;
                box-sizing: border-box;
                background-color: transparent;
                border-right: 1px solid $services-accent;
                border-left: 1px solid $services-accent;
            }
        }
    }
}

.team {
    .main-nav ul li a {
        color: $team-accent;

        span.bar {
            background-color: $team-accent;
    
        }
        &.nav-team {
            span.bar {
                background-color: transparent;
                border-color: $team-accent;
                box-sizing: border-box;
                background-color: transparent;
                border-right: 1px solid $team-accent;
                border-left: 1px solid $team-accent;
            }
        }
    }
}