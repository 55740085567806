// Normalize
@import "normalize";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300|Playfair+Display:900|Roboto+Condensed:300|Lato:300');

// Variables
@import "variables";
@import "typography";

// Global
@import "./Global/Body";

// Components
@import "./Components/MainHeader";
@import "./Components/MainFooter";
@import "./Components/MainNav";
@import "./Components/WorkButton";
@import "./Components/ScrollIndicator";
@import "./Components/Home";
@import "./Components/Work";
@import "./Components/WorkList";
@import "./Components/About";
@import "./Components/Services";
@import "./Components/Team";
@import "./Components/TeamList";
@import "./Components/Contact";
@import "./Components/ProjectDetail";
@import "./Components/Wipe";
@import "./Components/CheckmarkIndicator";