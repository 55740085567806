.project-detail-wrapper {
    background-color: $white;

    @media screen and (max-width: 769px) {
        &.project-startup-revival-wrapper {
            .background-image {
                background-position: center right;
            }
        }
    }

    h1 {
        color: $white;
        font-family: $serif;
        font-size: 6rem;
        font-weight: 900;
        line-height: 0.9;
        position: absolute;
        text-align: center;
        top: -82vh;
        width: 100vw;
        left: 0;
        padding: 20px;
        box-sizing: border-box;
        overflow: hidden;

        span {
            display: block;
        }

        @media screen and (max-width: 800px) {
            font-size: 5rem;
        }
    }

    h2 {
        font-family: $serif;
        font-weight: 900;
        font-size: 2em;
        margin-top: 0;
    }

    .wrap {
        max-width: none;
        padding: 0;
    }

    .hero-image {
        position: fixed;
        background-attachment: unset;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        .background-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    @media screen and (max-width: 700px) {
        &.project-startup-revival-wrapper {
            .hero-image {
                .background-image {
                    background-position: center right;
                }
            }
        }
    }
}

.cta-row {
    padding-bottom: 5rem;

    @media screen and (max-width: 680px) {
        padding-top: 5rem;
        
        .description-text-wide {
            max-width: 400px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.project-detail-inner {
    padding: 80px 60px;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (max-width: 700px) {
        padding: 60px 32px;
    }
}

.project-detail {
    position: relative;
    background-color: $white;
    padding: 3.5rem 0 160px;
    margin: 92vh auto 0;
    z-index: 1;
    color: $black;

    @media screen and (max-width: 680px) {
        padding-bottom: 100px;
    }

    .screen-view {
        position: relative;
        margin-top: -55vh;
        margin-bottom: 8rem;

        @media screen and (min-height: 1000px) {
            margin-top: -44vh;
        }

        @media screen and (max-width: 700px) {
            margin-top: -36vh;
        }
    }

    .bezel {
        max-width: 840px;
        background-color: #000000;
        padding: 2.25rem;
        margin: 0 auto;
        box-sizing: border-box;
        border-radius: 1rem;
        position: relative;

        @media screen and (max-width: 700px) {
            padding: 1.5rem;
        }

        @media screen and (max-width: 500px) {
            padding: 1rem;
        }

        .scroll-image-wrapper {
            width: 100%;
            padding-top: 62%;
            overflow: hidden;
            position: relative;
        }

        .scroll-image {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 1300px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .monitor-base {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    }

    .description-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 960px;
        margin: auto;

        > div {
            width: 50%;
            padding: 0;
            box-sizing: border-box;
        }

        .description-text {
            padding-right: 20px;

            &.description-text-wide {
                width: calc(100% - 300px);
            }

            p {
                font-size: 0.9em;
                line-height: 1.75;
                max-width: 480px;
            }
        }

        .logo-wrapper {
            padding: 0 20px;
            text-align: right;

            img {
                vertical-align: top;
                width: 100%;
                max-width: 300px;

            }
        }

        .bar-list-wrapper {
            padding-left: 20px;
            position: relative;
            max-width: 300px;

            h3 {
                font-family: $condensed;
                font-size: 1em;
                font-weight: 300;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin: 0 0 1rem -0.25rem;
            }

            ul {
                list-style-type: none;
                margin-bottom: 4rem;

                li {
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    font-family: $condensed;
                    letter-spacing: 1px;
                    margin-bottom: 0.75rem;
                }
            }

            .line-1,
            .line-2,
            .line-3 {
                position: absolute;
            }
            .line-1,
            .line-3 {
                background-color: $black;
            }
            .line-2,
            .line-3 {
                box-sizing: border-box;
                height: 5px;
            }
            .line-1 {
                background-color: $black;
                width: 1px;
                height: calc(100% - 2rem);
                left: 1rem;
                top: 2rem;
            }
            .line-2 {
                border-top: 1px solid $black;
                border-bottom: 1px solid $black;
                width: 80%;
                left: 0;
                bottom: 1rem;
            }
            .line-3 {
                width: 2.5rem;
                bottom: 1rem;
                left: calc(80% + 0.5rem);
            }
        }
        
        @media screen and (max-width: 680px) {
            > div {
                width: 100%;
            }

            .description-text,
            .logo-wrapper {
                padding: 0;
            }

            .description-text {
                margin-bottom: 60px;

                h2,
                p {
                    max-width: 400px;
                    margin-right: auto;
                    margin-left: auto;
                }

                p {
                    font-size: 1.1em;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                &.description-text-wide {
                    width: 100%;
                }
            }

            .logo-wrapper {
                text-align: center;
                
                img {
                    max-width: 240px;
                }
            }

            .bar-list-wrapper {
                margin: auto;
                max-width: 400px;

                h3 {
                    font-size: 1.1em;
                    margin-bottom: 2rem;
                    margin-left: -0.75rem;
                }

                ul {
                    li {
                        font-size: 1em;
                    }
                }
            }
        }
    }

    .comp-row {
        width: 100%;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            display: block;
        }
    }
}

.next-hero,
.next-button-hero {
    position: fixed;
    left: 0;
    top: 200vh;
    height: 100vh;
    width: 100vw;
    z-index: 100;

    @media screen and (max-width: 769px) {
        &.startup-revival-hero {
            .background-image {
                background-position: center right;
            }
        }

        &.godtv-hero {
            .background-image {
                background-position: center;
            }
        }
    }
}


.project-detail {
    &.project-serenity-wrapper {
        background-color: $serenity-blue;

        h2,
        h3 {
            color: $serenity-orange;
        }

        p {
            color: $serenity-text;
        }

        .bar-list-wrapper {
            ul {
                color: $serenity-gray;
            }
            
            .line-1,
            .line-3 {
                background-color: $serenity-orange;
            }
            .line-1 {
                background-color: $serenity-orange;
            }
            .line-2 {
                border-color: $serenity-orange;
            }
        }        
    }

    &.project-clc-wrapper {
        background-color: $clc-black;

        h2,
        h3 {
            color: $clc-red;
        }

        p {
            color: $clc-text;
        }

        .bar-list-wrapper {
            ul {
                color: $clc-text;
            }
            
            .line-1,
            .line-3 {
                background-color: $clc-red;
            }
            .line-1 {
                background-color: $clc-red;
            }
            .line-2 {
                border-color: $clc-red;
            }
        }        
    }

    &.project-startup-revival-wrapper {
        background-color: $sr-blue;

        h2,
        h3 {
            color: $sr-text;
        }

        p {
            color: $sr-text;
        }

        .bar-list-wrapper {
            ul {
                color: $sr-text;
            }
            
            .line-1,
            .line-3 {
                background-color: $sr-text;
            }
            .line-1 {
                background-color: $sr-text;
            }
            .line-2 {
                border-color: $sr-text;
            }
        }        
    }

    &.project-godtv-wrapper {
        background-color: $gtv-tan;

        h2,
        h3 {
            color: $gtv-text;
        }

        p {
            color: $gtv-text;
        }

        .bar-list-wrapper {
            ul {
                color: $gtv-text;
            }
            
            .line-1,
            .line-3 {
                background-color: $gtv-text;
            }
            .line-1 {
                background-color: $gtv-text;
            }
            .line-2 {
                border-color: $gtv-text;
            }
        }        
    }

    &.project-swatchit-wrapper {
        background-color: $swatchit-bg;

        h2,
        h3 {
            color: $swatchit-heading;
        }

        p {
            color: $swatchit-text;
        }

        .bar-list-wrapper {
            ul {
                color: $swatchit-text;
            }
            
            .line-1,
            .line-3 {
                background-color: $swatchit-heading;
            }
            .line-1 {
                background-color: $swatchit-heading;
            }
            .line-2 {
                border-color: $swatchit-heading;
            }
        }        
    }

    &.project-twotp-wrapper {
        background-color: $twotp-bg;

        h2,
        h3 {
            color: $twotp-heading;
        }

        p {
            color: $twotp-text;
        }

        .bar-list-wrapper {
            ul {
                color: $twotp-text;
            }
            
            .line-1,
            .line-3 {
                background-color: $twotp-heading;
            }
            .line-1 {
                background-color: $twotp-heading;
            }
            .line-2 {
                border-color: $twotp-heading;
            }
        }        
    }

    &.project-abf-wrapper {
        background-color: $abf-bg;

        h2,
        h3 {
            color: $abf-text;
        }

        p {
            color: $abf-text;
        }

        .bar-list-wrapper {
            ul {
                color: $abf-text;
            }
            
            .line-1,
            .line-3 {
                background-color: $abf-text;
            }
            .line-1 {
                background-color: $abf-text;
            }
            .line-2 {
                border-color: $abf-text;
            }
        }        
    }

    &.project-fbs-wrapper {
        background-color: $fbs-bg;

        h2,
        h3 {
            color: $fbs-heading;
        }

        p {
            color: $fbs-text;
        }

        .bar-list-wrapper {
            ul {
                color: $fbs-text;
            }
            
            .line-1,
            .line-3 {
                background-color: $fbs-heading;
            }
            .line-1 {
                background-color: $fbs-heading;
            }
            .line-2 {
                border-color: $fbs-heading;
            }
        }        
    }
}